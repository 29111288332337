/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useMemo, useReducer } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import type { TransfertsEditionDispatch, TransfertsEditionState } from "./transfertsEditionTypes";
import { transfertsEditionReducer } from "./transfertsEditionReducer";

const defaultDataContext: TransfertsEditionState = {
  actionParametres: {},
  isLoading: false,
  perimetre: undefined,
  _transferts: [],
  filteredTransferts: [],
  selectedTransferts: [],
};

type TransfertsEditionContextType = {
  state: TransfertsEditionState;
  dispatch: TransfertsEditionDispatch;
};

const TransfertsEditionContext = createContext<TransfertsEditionContextType | undefined>(undefined);

export function TransfertsEditionProvider({ children }: Readonly<PropsWithChildren>): ReactNode {
  const [state, dispatch] = useReducer(transfertsEditionReducer, defaultDataContext);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <TransfertsEditionContext.Provider value={value}>{children}</TransfertsEditionContext.Provider>
  );
}

export function useTransfertsEditionContext(): TransfertsEditionContextType {
  const context = useContext(TransfertsEditionContext);

  if (context == null) {
    throw new Error("useTransfertsEditionContext must be used within a TransfertsEditionProvider");
  }

  return context;
}
