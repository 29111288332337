/**
 * Attend un certain nombre de millisecondes. A utiliser avec **await**.
 * @param ms le nombre de millisecondes pendant lesquelles il faut attendre.
 */
async function sleep(ms: number): Promise<void> {
  await Promise.resolve(setTimeout(() => undefined, ms));
}

export const timeUtil = {
  sleep,
};
