import type { AuthenticationConfig } from "@sdeapps/react-core";

const APPLICATION_NAME = "Collectivites";
const DEFAULT_PAGE_TITLE = "Collectivités";

export type RouteDefinition = {
  path: string;
  getParameterPath: (...parameters: Array<string>) => string;
  title: string;
};
/**
 * Clés des routes déclarées / déclarables.
 * La syntaxe `.. as const` permet d'avoir un tableau immuable (enum like)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const declaredRoutesKeys = [
  "home",
  "commune",
  "epci",
  "communeAssocieeDeleguee",
  "territoire",
  "perimetre",
  "perimetreCommunesAssocieesDeleguees",
  "perimetreEditTransferts",
  "perimetreModify",
  "elu",
  "admin",
  "newPerimetre",
  "newTransferts",
  "fusionPerimetres",
  "dissoudrePerimetre",
  "any",
] as const;
/** Type d'union des clés de routes possibles afin de typer nos `routesConfig` */
type RoutesConfigKey = (typeof declaredRoutesKeys)[number];
type RoutesConfig = Record<RoutesConfigKey, RouteDefinition>;
const routesConfig: RoutesConfig = {
  home: { path: "/", getParameterPath: (_) => "/", title: DEFAULT_PAGE_TITLE },
  commune: {
    path: "/commune/:id",
    getParameterPath: (idCommune: string) => `/commune/${idCommune}`,
    title: "Commune",
  },
  epci: {
    path: "/epci/:id",
    getParameterPath: (idEpci: string) => `/epci/${idEpci}`,
    title: "EPCI",
  },
  communeAssocieeDeleguee: {
    path: "/commune-associee-deleguee/:id",
    getParameterPath: (idCad: string) => `/commune-associee-deleguee/${idCad}`,
    title: "Commune Associée / Déleguée",
  },
  territoire: {
    path: "/territoire/:id",
    getParameterPath: (idTerritoire: string) => `/territoire/${idTerritoire}`,
    title: "Territoire",
  },
  perimetre: {
    path: "/perimetre/:id",
    getParameterPath: (idPerimetre: string) => `/perimetre/${idPerimetre}`,
    title: "Périmètre",
  },
  perimetreCommunesAssocieesDeleguees: {
    path: "/perimetre/:id/communes-associees-deleguees",
    getParameterPath: (idPerimetre: string) =>
      `/perimetre/${idPerimetre}/communes-associees-deleguees`,
    title: DEFAULT_PAGE_TITLE,
  },
  perimetreEditTransferts: {
    path: "/perimetre/:id/modification-transferts",
    getParameterPath: (idPerimetre: string) => `/perimetre/${idPerimetre}/modification-transferts`,
    title: DEFAULT_PAGE_TITLE,
  },
  perimetreModify: {
    path: "/perimetre/:id/modification",
    getParameterPath: (idPerimetre: string) => `/perimetre/${idPerimetre}/modification`,
    title: DEFAULT_PAGE_TITLE,
  },
  elu: {
    path: "/elu/:id",
    getParameterPath: (idElu: string) => `/elu/${idElu}`,
    title: "Elu",
  },
  admin: {
    path: "/admin/",
    getParameterPath: (_) => `/admin/`,
    title: "Administration",
  },
  newPerimetre: {
    path: "/admin/nouveau-perimetre",
    getParameterPath: (_) => `/admin/nouveau-perimetre`,
    title: "Administration / Nouveau Périmètre",
  },
  newTransferts: {
    path: "/admin/nouveau-transfert",
    getParameterPath: (_) => `/admin/nouveau-transfert`,
    title: "Administration / Nouveau Transfert",
  },
  fusionPerimetres: {
    path: "/admin/fusion-perimetres",
    getParameterPath: (_) => `/admin/fusion-perimetres`,
    title: "Fusion de périmètres",
  },
  dissoudrePerimetre: {
    path: "/admin/dissoudre-perimetre",
    getParameterPath: (_) => `/admin/dissoudre-perimetre`,
    title: "Dissolution de périmètre",
  },
  any: { path: "*", getParameterPath: (_) => "*", title: DEFAULT_PAGE_TITLE },
};

export type RouteBaseObject = {
  path: string;
  element: string;
};
const routes: Array<RouteBaseObject> = Object.values(routesConfig).map(
  ({ path, title: element }) => ({ path, element })
);

const config = {
  basename: import.meta.env.PUBLIC_PATH,
  isBrowser: typeof window !== "undefined",
  appName: APPLICATION_NAME,
  appVersion: import.meta.env.VITE_VERSION ?? "?",
};

const authenticationConfig: AuthenticationConfig = {
  msal: {
    auth: {
      clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
      authority: import.meta.env.VITE_AUTH_AUTHORITY,
      knownAuthorities: [import.meta.env.VITE_AUTH_AUTHORITY ?? ""],
      redirectUri: import.meta.env.VITE_APP_BASE_URL,
      postLogoutRedirectUri: import.meta.env.VITE_APP_BASE_URL,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  },
  graph: {
    graphEndpoint: "https://graph.microsoft.com/v1.0",
    scopes: ["User.Read"],
  },
};

const apiConfig: {
  apimBaseUrl: string;
  apimSubscriptionKey: string;
  collectivitesApi: {
    scopes: Array<string>;
    apiName: string;
  };
  etablissementsPublicsApi: {
    scopes: Array<string>;
    apiName: string;
  };
  elusApi: {
    scopes: Array<string>;
    apiName: string;
  };
  azureAd: {
    scopes: Array<string>;
  };
  powerBiUrls: {
    activeData: string;
    transfersHistory: string;
    anomalies: string;
  };
} = {
  apimBaseUrl: import.meta.env.VITE_APIM_BASE_URL ?? "",
  apimSubscriptionKey: import.meta.env.VITE_APIM_SUBSCRIPTION_KEY ?? "",
  collectivitesApi: {
    scopes: import.meta.env?.VITE_COLLECTIVITES_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_COLLECTIVITES_API_NAME ?? "",
  },
  etablissementsPublicsApi: {
    scopes: import.meta.env?.VITE_ETABLISSEMENTS_PUBLICS_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_ETABLISSEMENTS_PUBLICS_API_NAME ?? "",
  },
  elusApi: {
    scopes: import.meta.env?.VITE_ELUS_SCOPES?.split(" ") ?? [],
    apiName: import.meta.env.VITE_ELUS_API_NAME ?? "",
  },
  azureAd: {
    scopes: ["User.Read", "User.ReadBasic.All"],
  },
  powerBiUrls: {
    activeData: import.meta.env.VITE_POWER_BI_ACTIVE_DATA_URL ?? "",
    transfersHistory: import.meta.env.VITE_POWER_BI_TRANSFERTS_HISTORY_URL ?? "",
    anomalies: import.meta.env.VITE_POWER_BI_ANOMALIES_DASHBOARD_URL ?? "",
  },
};

const appiConfig: { instrumentationKey: string } = {
  instrumentationKey: import.meta.env.VITE_APPI_INSTRUMENTATION_KEY ?? "",
};

const envConfig: { name: string } = {
  name: import.meta.env.VITE_ENV ?? "",
};

const graphConfig: { graphEndpoint: string } = {
  graphEndpoint: "https://graph.microsoft.com/v1.0",
};

export {
  config,
  DEFAULT_PAGE_TITLE,
  apiConfig,
  authenticationConfig,
  appiConfig,
  envConfig,
  routesConfig,
  routes,
  graphConfig,
};
