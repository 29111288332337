import { useMemo } from "react";
import type { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useTransfertsEditionContext } from "../store/transfertsEditionContext";
import { TransfertsEditionActionType } from "../store/transfertsEditionTypes";
import DialogMessages from "./TransfertEditionDialogMessages";

interface TransfertEditionConfirmationDialogProps {
  title?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isSending: boolean;
}

function TransfertEditionConfirmationDialog({
  title = "Confirmation requise",
  open,
  onConfirm,
  onCancel,
  isSending,
}: Readonly<TransfertEditionConfirmationDialogProps>): ReactNode {
  const {
    state: { selectedTransferts, actionUtilisateur },
  } = useTransfertsEditionContext();

  const contenu = useMemo((): ReactNode => {
    if (selectedTransferts.length > 0) {
      const count = selectedTransferts.length;
      switch (actionUtilisateur) {
        case TransfertsEditionActionType.ReaffectationEPCI:
          return <DialogMessages.ReaffectationEPCI count={count} />;
        case TransfertsEditionActionType.DirectIndirect:
          return <DialogMessages.DirectIndirect count={count} />;
        case TransfertsEditionActionType.IndirectDirect:
          return <DialogMessages.IndirectDirect count={count} />;
        case TransfertsEditionActionType.RetraitTransfert:
          return <DialogMessages.RetraitTransfert count={count} />;
        case TransfertsEditionActionType.SuppressionTransfert:
          return <DialogMessages.SuppressionTransfert count={count} />;
        case TransfertsEditionActionType.ReaffectationPerimetre:
        default:
          break;
      }
    }
    return undefined;
  }, [actionUtilisateur, selectedTransferts]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contenu}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="error" disabled={isSending}>
          Annuler
        </Button>
        <Button variant="contained" startIcon={<SaveIcon />} onClick={onConfirm}>
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TransfertEditionConfirmationDialog;
