import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, MenuItem } from "@mui/material";
import { competencesUtil } from "utils";
import { useErrorHandler } from "utils/errorHandling";
import LoadingScreen from "components/Template/LoadingScreen";
import type { Control } from "react-hook-form";
import { dateUtil } from "@sdeapps/react-core";
import { RaisonCreationTransfertLibelles } from "models";
import type { Perimetre, CreateTransfertsModelForm } from "models";
import { perimetresService } from "services";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import PerimetreAutoComplete from "components/inputs/PerimetreAutoComplete";
import ControlledTextField from "components/inputs/ControlledTextField";

interface NewTransfertInformationsPrincipalesProps {
  control: Control<CreateTransfertsModelForm>;
}

function NewTransfertInformationsPrincipales({
  control,
}: Readonly<NewTransfertInformationsPrincipalesProps>): ReactNode {
  const [perimetres, setPerimetres] = useState<Array<Perimetre>>([]);

  const { catchErrors, isLoading } = useErrorHandler();

  useEffect(() => {
    async function getPerimetres(): Promise<void> {
      const _perimetres = await perimetresService.getAll();
      _perimetres.sort(competencesUtil.sortPerimetresByCompetence);
      _perimetres.sort((a, b) => a.libelle.localeCompare(b.libelle));
      setPerimetres(_perimetres);
    }

    void catchErrors(getPerimetres);
  }, [catchErrors]);

  // TODO skeleton
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={2} size={12}>
      <Grid size={{ xs: 12, md: 4 }}>
        <ControlledDateTime
          name="dateDeliberation"
          control={control}
          rules={{
            required: "Choisir une date de délibération est obligatoire",
            validate: (value: Date | null) =>
              dateUtil.isValid(value) || "Veuillez renseigner une date valide",
          }}
          label="Date de délibération *"
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <ControlledDateTime
          name="dateAssembleeGenerale"
          control={control}
          rules={{
            required: "Choisir une date d'Assemblée Générale est obligatoire",
            validate: (value: Date | null) =>
              dateUtil.isValid(value) || "Veuillez renseigner une date valide",
          }}
          label="Date d'Assemblée Générale *"
        />
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <ControlledDateTime
          name="dateDebut"
          control={control}
          rules={{
            required: "Choisir une date de début est obligatoire",
            validate: (value: Date | null) =>
              dateUtil.isValid(value) || "Veuillez renseigner une date valide",
          }}
          label="Date de début *"
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <PerimetreAutoComplete
          name="perimetre"
          label="Périmètre concerné *"
          rules={{ required: "Ce champ est obligatoire" }}
          control={control}
          perimetres={perimetres}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ControlledTextField
          select
          name="raisonCreation"
          control={control}
          rules={{ required: "La raison de création de transfert est obligatoire" }}
          label="Raison de création de transfert *">
          {RaisonCreationTransfertLibelles.map((raison) => (
            <MenuItem key={raison} value={raison}>
              {raison}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
    </Grid>
  );
}

export default NewTransfertInformationsPrincipales;
